import { useGetOne, useRecordContext } from "react-admin";
import { MapperEditor } from "../../components/MapperEditor/MapperEditor";
import { useFormContext, useWatch } from "react-hook-form";
import { LoaderLayout } from "../../components/LoaderLayout";
import { Connector } from "../../generated";

export const ConnectorEditor = () => {
  const { control } = useFormContext();

  const inputs = useWatch({
    control,
    name: "inputs",
  });

  const outputs = useWatch({
    control,
    name: "outputs",
  });

  const record = useRecordContext();
  let connectorId;
  if (record) {
    if (record?.is_input) {
      connectorId = outputs?.[0]?.connector_id;
    } else {
      connectorId = inputs?.[0]?.input_connector_id;
    }
  } else {
    connectorId = null;
  }
  
  const connector = useGetOne<Connector>(
    "connectors",
    { id: connectorId },
    {
      enabled: !!connectorId,
    }
  );

  if (connector.isLoading) {
    return <LoaderLayout />;
  }

  return connector.data ? (
    <MapperEditor
      name="input_template"
      dataExample={
        connector.data.output_example
          ? JSON.parse(connector.data.output_example)
          : "{}"
      }
      connectorProperties={connector.data.properties}
    />
  ) : (
    <div>Linked connector not selected</div>
  );
};
