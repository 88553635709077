export const jinja2Mock = `{# Product mapping definition #}
{% set M = ({
  "PM-Mediaclip-Mug.White": "mug_product_msz_11-oz_mmat_ceramic-white_cl_4-0",
}) %}

{# Shipment helpers #}
{% set shipment = orderData.shipments|first %}
{% set shipTo = shipment.shipTo %}

{# Gelato API order v4 structure #}
{
  "orderType": "order",
  "orderReferenceId": "{{orderData.sourceOrderId}}",
  "customerReferenceId": "Pixme",
  "currency": "GBP",
  "items": [
{% for item in orderData["items"] %}
    {
      "itemReferenceId": {{item.sourceItemId|tojson}},
      "productUid": {{M[item.sku+"."+item.components[0].attributes.Colour]|tojson}},
      "files": [
        {
          "type": "default",
          "url": {{item.components[0].path|tojson}}
        }
      ],
      "quantity": {{item.quantity|tojson}},
      "metadata": [
        {
          "key": "unitWeight",
          "value": {{item.unitWeight|tojson}}
        },
        {
          "key": "unitPrice",
          "value": {{item.unitPrice|tojson}}
        },
        {
          "key": "supplierPartAuxiliaryId",
          "value": {{item.supplierPartAuxiliaryId|tojson}}
        }
      ]
    }{{ "," if not loop.last }}
{% endfor %}
  ],
  "metadata": [
    {
      "key": "gelato-os-logistics-profile",
      "value": {{shipment.carrier.alias|tojson}}
    }
  ],
  "shippingAddress": {
    "firstName": {{shipTo.name.split()|first|tojson}},
    "lastName": {{shipTo.name.split()|last|tojson}},
    "companyName": {{shipTo.companyName|tojson}},
    "addressLine1": {{shipTo.address1|tojson}},
    "addressLine2": {{shipTo.address2|tojson}},
    "city": {{shipTo.town|trim|tojson}},
    "postCode": {{shipTo.postcode|trim|tojson}},
    "state": {{shipTo.state|tojson}},
    "country": {{shipTo.isoCountry|tojson}},
    "email": {{shipTo.email|tojson}},
    "phone": {{shipTo.phone|tojson}},
    "isBusiness": false,
    "federalTaxId": null,
    "stateTaxId": null,
    "registrationStateCode": null
  },
  "shipmentMethodUid": {{shipment.carrier.alias|tojson}},
  "returnAddress": null
}
`;

export const jsonMock = `{
    "orderData": {
      "shipments": [
        {
          "shipTo": {
            "name": "Graham Duncan",
            "address1": "16E Seaton Place East ",
            "address2": "Aberdeen",
            "address3": "",
            "town": "Aberdeen ",
            "state": "Aberdeen Ciity",
            "postcode": "AB24 1XH ",
            "isoCountry": "GB",
            "country": "United Kingdom",
            "email": "grahamrobertduncan@gmail.com",
            "companyName": "",
            "phone": "0736067922"
          },
          "carrier": {
            "alias": "pmrm48"
          },
          "attachments": [],
          "shipmentIndex": 0,
          "pspBranding": true,
          "canShipEarly": true
        }
      ],
      "items": [
        {
          "components": [
            {
              "fetch": true,
              "localFile": false,
              "attributes": {
                "Substrate": "White Mug 11 oz",
                "ProductFinishedPageSize": "217 x 94 mm",
                "Colour": "White",
                "Extra": "N/A"
              },
              "extraData": [],
              "code": "Mug",
              "path": "https://renderstouse.blob.core.windows.net/9833e51f-42de-4800-8d7a-e980c45a49bd/f92655b4-2a6a-4fca-a469-6a82e91e8ef3_9833e51f-42de-4800-8d7a-e980c45a49bd.pdf?sv=2022-11-02&se=2029-05-29T03%3A10%3A06Z&sr=b&sp=r&sig=niyNZcVY7DJDCQDRYjazLZbyQt2EcXluwVVX7A118Ks%3D",
              "componentId": "5000006028A0101",
              "barcode": "5000006028A0101"
            }
          ],
          "shipmentIndex": 0,
          "sourceItemId": "50000060280101",
          "quantity": 1,
          "extraData": [],
          "sku": "PM-Mediaclip-Mug",
          "barcode": "50000060280101",
          "unitWeight": 350,
          "unitPrice": 3.32,
          "isoCountryOfOrigin": "GB",
          "harmonizedCode": "44199090",
          "supplierPartAuxiliaryId": "01314f66-a271-4ade-bcb1-d3403fad3a8d"
        }
      ],
      "stockItems": [],
      "error": [],
      "extraData": [],
      "printType": "digital",
      "sourceOrderId": "5000006028",
      "email": "",
      "amount": 0,
      "customerName": "Graham Duncan"
    },
    "destination": {
      "name": "hp.espcolour"
    }
  }`;
