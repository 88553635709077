import { AuthApi } from "../generated/api/auth-api";
import {
  AccountsApi,
  Configuration,
  ConnectorsApi,
  LookupsApi,
  RequestsApi,
  TemplateApi,
} from "../generated";
import Keycloak from "keycloak-js";
import React from "react";

const readApiBaseFromEnv = (): string => {
  // Get API base URL from env
  // Priority is given to same host in the browser when environemnt is production
  if (
    process.env.NODE_ENV === "production" &&
    !document.location.host.startsWith("localhost")
  ) {
    return `https://${document.location.host}`;
  } else if (process.env.REACT_APP_API_BASE) {
    return process.env.REACT_APP_API_BASE;
  }
  return "http://localhost:8000";
};

export const basePath: string = readApiBaseFromEnv();

const createApiContextValue = (keycloakClient?: Keycloak): ApiContextValue => {
  const readAccessToken = async (): Promise<string> => {
    if (keycloakClient?.token) {
      return keycloakClient.token;
    }
    return "";
  };

  const basePath: string = readApiBaseFromEnv();

  const apiConfig: Configuration = new Configuration({
    basePath,
    accessToken: readAccessToken,
  });

  const connectorApi: ConnectorsApi = new ConnectorsApi(apiConfig);
  const templateApi: TemplateApi = new TemplateApi(apiConfig);
  const authApi: AuthApi = new AuthApi(apiConfig);
  const accountsApi: AccountsApi = new AccountsApi(apiConfig);
  const lookupsApi: LookupsApi = new LookupsApi(apiConfig);
  const requestsApi: RequestsApi = new RequestsApi(apiConfig);

  return {
    connectorApi,
    templateApi,
    authApi,
    accountsApi,
    lookupsApi,
    requestsApi,
  };
};

export type ApiContextValue = {
  connectorApi: ConnectorsApi;
  templateApi: TemplateApi;
  authApi: AuthApi;
  accountsApi: AccountsApi;
  lookupsApi: LookupsApi;
  requestsApi: RequestsApi;
};

export const ApiContext = React.createContext<ApiContextValue>(
  createApiContextValue()
);

export const ApiProvider: React.FC<{
  keycloakClient?: Keycloak;
}> = ({ children, keycloakClient }) => {
  return (
    <ApiContext.Provider value={createApiContextValue(keycloakClient)}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = (): ApiContextValue => {
  return React.useContext(ApiContext);
};
