import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { useFormContext, Controller } from "react-hook-form";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CLOSED_DRAWER_WIDTH } from "react-admin";

export const ConnectorOutputEditor = () => {
  const { control } = useFormContext();

  return (
    <Container container spacing={2}>
      <Grid item xs={12}>
        <Title>Data example</Title>
        <Controller
          control={control}
          name="output_example"
          render={({ field }) => (
            <CodeMirror
              value={field.value ?? ""}
              onChange={(value) => {
                field.onChange(value);
              }}
              height="70vh"
              extensions={[json()]}
              basicSetup={{
                lineNumbers: false,
              }}
            />
          )}
        />
      </Grid>
    </Container>
  );
};

const Container = styled(Grid)(() => ({
  width: "100%",
  maxWidth: `calc(100vw - ${CLOSED_DRAWER_WIDTH + 32}px)`,
  fontSize: "10px",
}));

const Title = styled("h3")(() => ({
  margin: 0,
}));
