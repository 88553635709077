import { FC, useState } from "react";
import Grid from "@mui/material/Grid";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { StreamLanguage } from "@codemirror/language";
import { styled } from "@mui/material/styles";
import { jinja2 } from "@codemirror/legacy-modes/mode/jinja2";
import { jsonMock } from "./mocks";
import { Button, CLOSED_DRAWER_WIDTH } from "react-admin";
import { useFormContext, Controller, useWatch } from "react-hook-form";
import { useApi } from "../../providers/env";
import { ConnectorProperty } from "../../generated/models/connector-property";

export type MapperEditorProps = {
  name: string;
  dataExample?: Object;
  connectorProperties?: Object;
};

export const MapperEditor: FC<MapperEditorProps> = ({
  name,
  dataExample: dataExampleDefault,
  connectorProperties,
}) => {
  const { control } = useFormContext();
  const [dataExample, setDataExample] = useState(
    dataExampleDefault ? JSON.stringify(dataExampleDefault, null, 2) : jsonMock
  );
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);

  const code = useWatch({
    control,
    name,
  });

  const { templateApi } = useApi();

  const render = async () => {
    if (!loading) {
      setLoading(true);
      try {
        let properties = new Map();
        const propertiesArray = connectorProperties as Array<ConnectorProperty>;
        propertiesArray.forEach(p => {
          properties.set(p.key, p.value);
        }); 

        const result = await templateApi.renderTemplate({
          templateRender: {
            template: code,
            data: JSON.parse(dataExample),
            properties: Object.fromEntries(properties),
          },
        });

        setResult(result.data);
      } catch (e) {
        setResult(e.message);
        console.error(e);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Container container spacing={2}>
      <Grid item xs={4}>
        <Title>Data example</Title>
        <CodeMirror
          width="100%"
          value={dataExample}
          onChange={(value) => {
            setDataExample(value);
          }}
          height="70vh"
          extensions={[json()]}
          basicSetup={{
            lineNumbers: false,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Title>Mapper template</Title>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <CodeMirror
                value={field.value ?? ""}
                onChange={(value) => {
                  field.onChange(value);
                }}
                onBlur={field.onBlur}
                height="70vh"
                width="100%"
                extensions={[StreamLanguage.define(jinja2)]}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Title>Result</Title>
        <CodeMirror
          value={result}
          height="70vh"
          width="100%"
          readOnly
          extensions={[json()]}
          basicSetup={{
            lineNumbers: false,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          variant="contained"
          label="Render"
          disabled={loading}
          onClick={() => {
            render();
          }}
        />
      </Grid>
    </Container>
  );
};

const Container = styled(Grid)(() => ({
  width: "100%",
  maxWidth: `calc(100vw - ${CLOSED_DRAWER_WIDTH + 32}px)`,
  fontSize: "10px",
}));

const Title = styled("h3")(() => ({
  margin: 0,
}));
