/* tslint:disable */
/* eslint-disable */
/**
 * middleware
 * middleware API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Connector } from '../models';
// @ts-ignore
import { ConnectorCreate } from '../models';
// @ts-ignore
import { ConnectorMetrics } from '../models';
// @ts-ignore
import { ConnectorUpdate } from '../models';
// @ts-ignore
import { HTTPValidationError } from '../models';
/**
 * ConnectorsApi - axios parameter creator
 * @export
 */
export const ConnectorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Connector
         * @param {ConnectorCreate} connectorCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnector: async (connectorCreate: ConnectorCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectorCreate' is not null or undefined
            assertParamExists('createConnector', 'connectorCreate', connectorCreate)
            const localVarPath = `/api/v1/connectors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2AuthorizationCodeBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2AuthorizationCodeBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectorCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Connector
         * @param {number} connectorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConnector: async (connectorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectorId' is not null or undefined
            assertParamExists('deleteConnector', 'connectorId', connectorId)
            const localVarPath = `/api/v1/connectors/{connector_id}`
                .replace(`{${"connector_id"}}`, encodeURIComponent(String(connectorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2AuthorizationCodeBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2AuthorizationCodeBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Connector
         * @param {number} connectorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnector: async (connectorId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectorId' is not null or undefined
            assertParamExists('getConnector', 'connectorId', connectorId)
            const localVarPath = `/api/v1/connectors/{connector_id}`
                .replace(`{${"connector_id"}}`, encodeURIComponent(String(connectorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2AuthorizationCodeBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2AuthorizationCodeBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Connector Metrics
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorMetrics: async (sort?: string, range?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/connectors/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2AuthorizationCodeBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2AuthorizationCodeBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Connectors
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectors: async (sort?: string, range?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/connectors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2AuthorizationCodeBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2AuthorizationCodeBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Connector
         * @param {number} connectorId 
         * @param {ConnectorUpdate} connectorUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnector: async (connectorId: number, connectorUpdate: ConnectorUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectorId' is not null or undefined
            assertParamExists('updateConnector', 'connectorId', connectorId)
            // verify required parameter 'connectorUpdate' is not null or undefined
            assertParamExists('updateConnector', 'connectorUpdate', connectorUpdate)
            const localVarPath = `/api/v1/connectors/{connector_id}`
                .replace(`{${"connector_id"}}`, encodeURIComponent(String(connectorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2AuthorizationCodeBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2AuthorizationCodeBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectorUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectorsApi - functional programming interface
 * @export
 */
export const ConnectorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectorsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Connector
         * @param {ConnectorCreate} connectorCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConnector(connectorCreate: ConnectorCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Connector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConnector(connectorCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Connector
         * @param {number} connectorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConnector(connectorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConnector(connectorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Connector
         * @param {number} connectorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnector(connectorId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Connector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnector(connectorId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Connector Metrics
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectorMetrics(sort?: string, range?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConnectorMetrics>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectorMetrics(sort, range, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Connectors
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectors(sort?: string, range?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Connector>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectors(sort, range, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Connector
         * @param {number} connectorId 
         * @param {ConnectorUpdate} connectorUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnector(connectorId: number, connectorUpdate: ConnectorUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Connector>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnector(connectorId, connectorUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConnectorsApi - factory interface
 * @export
 */
export const ConnectorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectorsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Connector
         * @param {ConnectorCreate} connectorCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnector(connectorCreate: ConnectorCreate, options?: any): AxiosPromise<Connector> {
            return localVarFp.createConnector(connectorCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Connector
         * @param {number} connectorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConnector(connectorId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteConnector(connectorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Connector
         * @param {number} connectorId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnector(connectorId: number, options?: any): AxiosPromise<Connector> {
            return localVarFp.getConnector(connectorId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Connector Metrics
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectorMetrics(sort?: string, range?: string, options?: any): AxiosPromise<Array<ConnectorMetrics>> {
            return localVarFp.getConnectorMetrics(sort, range, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Connectors
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectors(sort?: string, range?: string, options?: any): AxiosPromise<Array<Connector>> {
            return localVarFp.getConnectors(sort, range, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Connector
         * @param {number} connectorId 
         * @param {ConnectorUpdate} connectorUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnector(connectorId: number, connectorUpdate: ConnectorUpdate, options?: any): AxiosPromise<Connector> {
            return localVarFp.updateConnector(connectorId, connectorUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createConnector operation in ConnectorsApi.
 * @export
 * @interface ConnectorsApiCreateConnectorRequest
 */
export interface ConnectorsApiCreateConnectorRequest {
    /**
     * 
     * @type {ConnectorCreate}
     * @memberof ConnectorsApiCreateConnector
     */
    readonly connectorCreate: ConnectorCreate
}

/**
 * Request parameters for deleteConnector operation in ConnectorsApi.
 * @export
 * @interface ConnectorsApiDeleteConnectorRequest
 */
export interface ConnectorsApiDeleteConnectorRequest {
    /**
     * 
     * @type {number}
     * @memberof ConnectorsApiDeleteConnector
     */
    readonly connectorId: number
}

/**
 * Request parameters for getConnector operation in ConnectorsApi.
 * @export
 * @interface ConnectorsApiGetConnectorRequest
 */
export interface ConnectorsApiGetConnectorRequest {
    /**
     * 
     * @type {number}
     * @memberof ConnectorsApiGetConnector
     */
    readonly connectorId: number
}

/**
 * Request parameters for getConnectorMetrics operation in ConnectorsApi.
 * @export
 * @interface ConnectorsApiGetConnectorMetricsRequest
 */
export interface ConnectorsApiGetConnectorMetricsRequest {
    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof ConnectorsApiGetConnectorMetrics
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof ConnectorsApiGetConnectorMetrics
     */
    readonly range?: string
}

/**
 * Request parameters for getConnectors operation in ConnectorsApi.
 * @export
 * @interface ConnectorsApiGetConnectorsRequest
 */
export interface ConnectorsApiGetConnectorsRequest {
    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof ConnectorsApiGetConnectors
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof ConnectorsApiGetConnectors
     */
    readonly range?: string
}

/**
 * Request parameters for updateConnector operation in ConnectorsApi.
 * @export
 * @interface ConnectorsApiUpdateConnectorRequest
 */
export interface ConnectorsApiUpdateConnectorRequest {
    /**
     * 
     * @type {number}
     * @memberof ConnectorsApiUpdateConnector
     */
    readonly connectorId: number

    /**
     * 
     * @type {ConnectorUpdate}
     * @memberof ConnectorsApiUpdateConnector
     */
    readonly connectorUpdate: ConnectorUpdate
}

/**
 * ConnectorsApi - object-oriented interface
 * @export
 * @class ConnectorsApi
 * @extends {BaseAPI}
 */
export class ConnectorsApi extends BaseAPI {
    /**
     * 
     * @summary Create Connector
     * @param {ConnectorsApiCreateConnectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    public createConnector(requestParameters: ConnectorsApiCreateConnectorRequest, options?: AxiosRequestConfig) {
        return ConnectorsApiFp(this.configuration).createConnector(requestParameters.connectorCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Connector
     * @param {ConnectorsApiDeleteConnectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    public deleteConnector(requestParameters: ConnectorsApiDeleteConnectorRequest, options?: AxiosRequestConfig) {
        return ConnectorsApiFp(this.configuration).deleteConnector(requestParameters.connectorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Connector
     * @param {ConnectorsApiGetConnectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    public getConnector(requestParameters: ConnectorsApiGetConnectorRequest, options?: AxiosRequestConfig) {
        return ConnectorsApiFp(this.configuration).getConnector(requestParameters.connectorId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Connector Metrics
     * @param {ConnectorsApiGetConnectorMetricsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    public getConnectorMetrics(requestParameters: ConnectorsApiGetConnectorMetricsRequest = {}, options?: AxiosRequestConfig) {
        return ConnectorsApiFp(this.configuration).getConnectorMetrics(requestParameters.sort, requestParameters.range, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Connectors
     * @param {ConnectorsApiGetConnectorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    public getConnectors(requestParameters: ConnectorsApiGetConnectorsRequest = {}, options?: AxiosRequestConfig) {
        return ConnectorsApiFp(this.configuration).getConnectors(requestParameters.sort, requestParameters.range, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Connector
     * @param {ConnectorsApiUpdateConnectorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectorsApi
     */
    public updateConnector(requestParameters: ConnectorsApiUpdateConnectorRequest, options?: AxiosRequestConfig) {
        return ConnectorsApiFp(this.configuration).updateConnector(requestParameters.connectorId, requestParameters.connectorUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
