import simpleRestProvider from "ra-data-simple-rest";
import {createBrowserHistory as createHistory} from "history";
import {Admin, AuthProvider, CustomRoutes, DataProvider, Resource,} from "react-admin";
import MyLayout from "./components/AdminLayout";
import Dashboard from "./pages/Dashboard";
import {keycloakAuthProvider} from "./providers/authProvider";
import {ApiProvider, basePath} from "./providers/env";
import Keycloak, {KeycloakInitOptions, KeycloakTokenParsed,} from "keycloak-js";
import {httpClient} from "./httpClient";
import {useEffect, useRef, useState} from "react";
import {LoaderLayout} from "./components/LoaderLayout";
import {keycloakConfig} from "./keycloak";
import {Route} from "react-router";
import GroupIcon from "@mui/icons-material/Group";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ViewListIcon from "@mui/icons-material/ViewList";
import jwt_decode from "jwt-decode";
import {AccountCreate, AccountEdit, AccountList} from "./pages/Accounts";
import {ConnectorCreate, ConnectorEdit, ConnectorList,} from "./pages/connectors/Connectors";
import {LookupCreate, LookupEdit, LookupList} from "./pages/Lookups";
import {RequestList, RequestShow} from "./pages/Requests";
import {Profile} from "./pages/profile/Profile";


const initOptions: KeycloakInitOptions = {
  onLoad: "login-required",
  checkLoginIframe: false,
};

const getPermissions = (decoded: KeycloakTokenParsed) => {
  const roles = decoded?.realm_access?.roles;
  if (!roles) {
    return false;
  }

  return true;
};

const App = () => {
  console.log("🚀 ~ file: App.tsx:111 ~ App ~ App render:");

  const [keycloak, setKeycloak] = useState<Keycloak | undefined>(undefined);
  const authProvider = useRef<AuthProvider | null>(null);
  const dataProvider = useRef<DataProvider | null>(null);

  useEffect(() => {
    const initKeyCloakClient = async () => {
      try {
        const keycloakClient = new Keycloak(keycloakConfig);
        await keycloakClient.init(initOptions);
        if (keycloakClient && keycloakClient.token) {
          const decoded = jwt_decode<KeycloakTokenParsed>(keycloakClient.token);

          setInterval(() => {
            keycloakClient.updateToken(5).then((refreshed) => {
              if (refreshed) {
                console.log(" refreshed");
              }
            });
          }, decoded.exp! * 1000 - Date.now());
        }
        authProvider.current = keycloakAuthProvider(keycloakClient, {
          onPermissions: getPermissions,
        });
        dataProvider.current = simpleRestProvider(
          `${basePath}/api/v1`,
          httpClient(keycloakClient)
        );
        setKeycloak(keycloakClient);
      } catch (error) {
        console.log("🚀 ~ file: App.tsx:65 ~ useEffect ~ error:", error);
      }
    };
    if (!keycloak) {
      initKeyCloakClient();
    }
  }, []);

  if (!keycloak) {
    return <LoaderLayout />;
  }

  return (
    <ApiProvider keycloakClient={keycloak}>
      <Admin
        disableTelemetry
        dataProvider={dataProvider.current!}
        authProvider={authProvider.current!}
        history={createHistory()}
        layout={MyLayout}
        dashboard={Dashboard}
      >
        <CustomRoutes>
          <Route path="/my-profile" element={<Profile />} />
        </CustomRoutes>

        <Resource
          name="accounts"
          options={{ label: "Account" }}
          recordRepresentation="name"
          list={AccountList}
          edit={AccountEdit}
          create={AccountCreate}
          icon={GroupIcon}
        />
        <Resource
          name="connectors"
          options={{ label: "Connectors" }}
          recordRepresentation="name"
          list={ConnectorList}
          edit={ConnectorEdit}
          create={ConnectorCreate}
          icon={DeviceHubIcon}
        />
        <Resource
          name="lookups"
          options={{ label: "Lookups" }}
          recordRepresentation="name"
          list={LookupList}
          edit={LookupEdit}
          create={LookupCreate}
          icon={ManageSearchIcon}
        />
        <Resource
          name="requests"
          options={{ label: "Requests" }}
          recordRepresentation="created_at"
          list={RequestList}
          show={RequestShow}
          icon={ViewListIcon}
        />
      </Admin>
    </ApiProvider>
  );
};

export default App;
