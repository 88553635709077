import { Grid, CircularProgress } from "@mui/material";
import styles from "./LoaderLayout.module.css";

export const LoaderLayout = () => {
  return (
    <Grid container>
      <Grid
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={styles.progressWrapper}
      >
        <CircularProgress />
      </Grid>
    </Grid>
  );
};
