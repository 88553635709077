import {
  Create,
  Edit,
  Datagrid,
  EditButton,
  List,
  TextField,
  TextInput,
  SelectInput,
  BooleanInput,
  PasswordInput,
  BooleanField,
  SelectField,
  TabbedForm,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  ArrayField,
  ReferenceField,
} from "react-admin";
import { Grid } from "@mui/material";
import { Stack } from "@mui/material";
import { ConnectorEditor } from "./ConnectorEditor";
import { ConnectorOutputEditor } from "./ConnectorOutputExample";
import InputIcon from '@mui/icons-material/Login';
import OutputIcon from '@mui/icons-material/Logout';

const ConnectorTypeOptions = [
  { id: "SiteFlow", name: "SiteFlow" },
  { id: "GelatoAPI", name: "GelatoAPI" },
  { id: "PostJSON", name: "PostJSON" },
  { id: "Proxy", name: "Proxy" },
  { id: "Email", name: "Email" },
];

const ConnectorRequestMethodOptions = [
  { id: "get", name: "GET" },
  { id: "post", name: "POST" },
  { id: "put", name: "PUT" },
  { id: "delete", name: "DELETE" },
];

export const ConnectorList = (props: any) => (
  <List {...props} filters={[]} exporter={false}>
    <Datagrid>
      <SelectField source="type" choices={ConnectorTypeOptions} />
      <SelectField source="request_method" choices={ConnectorRequestMethodOptions} />
      <BooleanField source="is_input" label="Direction" TrueIcon={InputIcon} FalseIcon={OutputIcon} valueLabelTrue={"Input"} valueLabelFalse={"Output"} />
      <TextField source="name" />
      <TextField source="description" />
      <BooleanField source="is_active" />
      <EditButton />
    </Datagrid>
  </List>
);

const ConnectorCreateEditUI = (props: any) => {
  return (
    <TabbedForm>
      <TabbedForm.Tab label="Details">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <SelectInput source="type" choices={ConnectorTypeOptions} />
              <SelectInput source="request_method" choices={ConnectorRequestMethodOptions} />
              <TextInput source="name" label="Name" />
              <TextInput source="description" label="Description" />
              <BooleanInput source="is_input" />
              <BooleanInput source="is_active" />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <TextInput source="endpoint" />
              <TextInput source="key_id" />
              <PasswordInput source="key_secret" />
              <TextInput source="filter" />
            </Stack>
          </Grid>
        </Grid>
      </TabbedForm.Tab>

      <TabbedForm.Tab label="Input">
        <ConnectorEditor />
      </TabbedForm.Tab>

      <TabbedForm.Tab label="Output">
        <ConnectorOutputEditor />
      </TabbedForm.Tab>

      <TabbedForm.Tab label="Connectors">
        <ArrayInput source="inputs" label="Inputs Connectors">
          <SimpleFormIterator inline disableClear>
            <ReferenceInput source="input_connector_id" reference="connectors" perPage="10000">
              <SelectInput source="id" />
            </ReferenceInput>
            <TextInput source="filter" />
            <BooleanInput source="is_active" />
            <BooleanInput source="is_fallback" />
          </SimpleFormIterator>
        </ArrayInput>

        <ArrayField source="outputs" label="Output Connectors">
          <Datagrid bulkActionButtons={false} empty={ <div></div> }>
            <ReferenceField source="connector_id" reference="connectors">
              <TextField source="type" />: <TextField source="name" />
            </ReferenceField>
            <TextField source="filter" />
            <BooleanField source="is_active" />
            <BooleanField source="is_fallback" />
          </Datagrid>
        </ArrayField>
      </TabbedForm.Tab>

      <TabbedForm.Tab label="Properties">
        <ArrayInput source="properties" label="Properties">
          <SimpleFormIterator inline>
            <TextInput source="key" />
            <TextInput source="value" />
          </SimpleFormIterator>
        </ArrayInput>
      </TabbedForm.Tab>
    </TabbedForm>
  );
};

export const ConnectorCreate = (props: any) => {
  console.log("ConnectorCreate");
  return (
    <Create {...props}>
      <ConnectorCreateEditUI {...props} />
    </Create>
  );
};

export const ConnectorEdit = (props: any) => {
  console.log("ConnectorEdit");
  return (
    <Edit {...props}>
      <ConnectorCreateEditUI {...props} />
    </Edit>
  );
};
