/* tslint:disable */
/* eslint-disable */
/**
 * middleware
 * middleware API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { Lookup } from '../models';
// @ts-ignore
import { LookupCreate } from '../models';
// @ts-ignore
import { LookupUpdate } from '../models';
/**
 * LookupsApi - axios parameter creator
 * @export
 */
export const LookupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Lookup
         * @param {LookupCreate} lookupCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLookup: async (lookupCreate: LookupCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lookupCreate' is not null or undefined
            assertParamExists('createLookup', 'lookupCreate', lookupCreate)
            const localVarPath = `/api/v1/lookups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2AuthorizationCodeBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2AuthorizationCodeBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lookupCreate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Lookup
         * @param {number} lookupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLookup: async (lookupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lookupId' is not null or undefined
            assertParamExists('deleteLookup', 'lookupId', lookupId)
            const localVarPath = `/api/v1/lookups/{lookup_id}`
                .replace(`{${"lookup_id"}}`, encodeURIComponent(String(lookupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2AuthorizationCodeBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2AuthorizationCodeBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Lookup
         * @param {number} lookupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLookup: async (lookupId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lookupId' is not null or undefined
            assertParamExists('getLookup', 'lookupId', lookupId)
            const localVarPath = `/api/v1/lookups/{lookup_id}`
                .replace(`{${"lookup_id"}}`, encodeURIComponent(String(lookupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2AuthorizationCodeBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2AuthorizationCodeBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Lookups
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLookups: async (sort?: string, range?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/lookups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2AuthorizationCodeBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2AuthorizationCodeBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Lookup
         * @param {number} lookupId 
         * @param {LookupUpdate} lookupUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLookup: async (lookupId: number, lookupUpdate: LookupUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lookupId' is not null or undefined
            assertParamExists('updateLookup', 'lookupId', lookupId)
            // verify required parameter 'lookupUpdate' is not null or undefined
            assertParamExists('updateLookup', 'lookupUpdate', lookupUpdate)
            const localVarPath = `/api/v1/lookups/{lookup_id}`
                .replace(`{${"lookup_id"}}`, encodeURIComponent(String(lookupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2AuthorizationCodeBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2AuthorizationCodeBearer", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lookupUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LookupsApi - functional programming interface
 * @export
 */
export const LookupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LookupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Lookup
         * @param {LookupCreate} lookupCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLookup(lookupCreate: LookupCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lookup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLookup(lookupCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Lookup
         * @param {number} lookupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLookup(lookupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLookup(lookupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Lookup
         * @param {number} lookupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLookup(lookupId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lookup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLookup(lookupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Lookups
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLookups(sort?: string, range?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Lookup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLookups(sort, range, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Lookup
         * @param {number} lookupId 
         * @param {LookupUpdate} lookupUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLookup(lookupId: number, lookupUpdate: LookupUpdate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Lookup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLookup(lookupId, lookupUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LookupsApi - factory interface
 * @export
 */
export const LookupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LookupsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Lookup
         * @param {LookupCreate} lookupCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLookup(lookupCreate: LookupCreate, options?: any): AxiosPromise<Lookup> {
            return localVarFp.createLookup(lookupCreate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Lookup
         * @param {number} lookupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLookup(lookupId: number, options?: any): AxiosPromise<any> {
            return localVarFp.deleteLookup(lookupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Lookup
         * @param {number} lookupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLookup(lookupId: number, options?: any): AxiosPromise<Lookup> {
            return localVarFp.getLookup(lookupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Lookups
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLookups(sort?: string, range?: string, options?: any): AxiosPromise<Array<Lookup>> {
            return localVarFp.getLookups(sort, range, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Lookup
         * @param {number} lookupId 
         * @param {LookupUpdate} lookupUpdate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLookup(lookupId: number, lookupUpdate: LookupUpdate, options?: any): AxiosPromise<Lookup> {
            return localVarFp.updateLookup(lookupId, lookupUpdate, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createLookup operation in LookupsApi.
 * @export
 * @interface LookupsApiCreateLookupRequest
 */
export interface LookupsApiCreateLookupRequest {
    /**
     * 
     * @type {LookupCreate}
     * @memberof LookupsApiCreateLookup
     */
    readonly lookupCreate: LookupCreate
}

/**
 * Request parameters for deleteLookup operation in LookupsApi.
 * @export
 * @interface LookupsApiDeleteLookupRequest
 */
export interface LookupsApiDeleteLookupRequest {
    /**
     * 
     * @type {number}
     * @memberof LookupsApiDeleteLookup
     */
    readonly lookupId: number
}

/**
 * Request parameters for getLookup operation in LookupsApi.
 * @export
 * @interface LookupsApiGetLookupRequest
 */
export interface LookupsApiGetLookupRequest {
    /**
     * 
     * @type {number}
     * @memberof LookupsApiGetLookup
     */
    readonly lookupId: number
}

/**
 * Request parameters for getLookups operation in LookupsApi.
 * @export
 * @interface LookupsApiGetLookupsRequest
 */
export interface LookupsApiGetLookupsRequest {
    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof LookupsApiGetLookups
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof LookupsApiGetLookups
     */
    readonly range?: string
}

/**
 * Request parameters for updateLookup operation in LookupsApi.
 * @export
 * @interface LookupsApiUpdateLookupRequest
 */
export interface LookupsApiUpdateLookupRequest {
    /**
     * 
     * @type {number}
     * @memberof LookupsApiUpdateLookup
     */
    readonly lookupId: number

    /**
     * 
     * @type {LookupUpdate}
     * @memberof LookupsApiUpdateLookup
     */
    readonly lookupUpdate: LookupUpdate
}

/**
 * LookupsApi - object-oriented interface
 * @export
 * @class LookupsApi
 * @extends {BaseAPI}
 */
export class LookupsApi extends BaseAPI {
    /**
     * 
     * @summary Create Lookup
     * @param {LookupsApiCreateLookupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LookupsApi
     */
    public createLookup(requestParameters: LookupsApiCreateLookupRequest, options?: AxiosRequestConfig) {
        return LookupsApiFp(this.configuration).createLookup(requestParameters.lookupCreate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Lookup
     * @param {LookupsApiDeleteLookupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LookupsApi
     */
    public deleteLookup(requestParameters: LookupsApiDeleteLookupRequest, options?: AxiosRequestConfig) {
        return LookupsApiFp(this.configuration).deleteLookup(requestParameters.lookupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Lookup
     * @param {LookupsApiGetLookupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LookupsApi
     */
    public getLookup(requestParameters: LookupsApiGetLookupRequest, options?: AxiosRequestConfig) {
        return LookupsApiFp(this.configuration).getLookup(requestParameters.lookupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Lookups
     * @param {LookupsApiGetLookupsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LookupsApi
     */
    public getLookups(requestParameters: LookupsApiGetLookupsRequest = {}, options?: AxiosRequestConfig) {
        return LookupsApiFp(this.configuration).getLookups(requestParameters.sort, requestParameters.range, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Lookup
     * @param {LookupsApiUpdateLookupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LookupsApi
     */
    public updateLookup(requestParameters: LookupsApiUpdateLookupRequest, options?: AxiosRequestConfig) {
        return LookupsApiFp(this.configuration).updateLookup(requestParameters.lookupId, requestParameters.lookupUpdate, options).then((request) => request(this.axios, this.basePath));
    }
}
