import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { Datagrid, List, NumberField, TextField, ReferenceField, ListBase, Title } from "react-admin";

const Dashboard = () => (
  <Card>
    <CardHeader title="Active Inputs" />
    <CardContent>
      <div>List of enabled input connectors with metrics for the past 7 days.</div>
      <ListBase resource="connectors/metrics" disableSyncWithLocation perPage={100}>
        <List sort={{ field: 'endpoint', order: 'ASC' }} exporter={false} title={"Dashboard"}>
          <Datagrid bulkActionButtons={false}>
            <TextField source="endpoint" />
            <NumberField source="requests" />
            <NumberField source="successful" />
            <ReferenceField source="id" reference="connectors" label="Connector" />
            <ReferenceField source="account_id" reference="accounts" label="Account" />
          </Datagrid>
        </List>
      </ListBase>
    </CardContent>
  </Card>
);

export default Dashboard;
