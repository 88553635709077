import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  BooleanInput,
  BooleanField,
  ArrayInput,
  SimpleFormIterator
} from "react-admin";
import { Stack } from "@mui/material";

export const LookupList = (props: any) => (
  <List {...props} filters={[]} exporter={false}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="key_template" />
      <BooleanField source="is_active" />
      <EditButton />
    </Datagrid>
  </List>
);

export const LookupEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm>
      <Stack spacing={1} style={{ width: "100%" }}>
        <TextInput source="name" sx={{ maxWidth: 400}} />
        <TextInput source="description" sx={{ maxWidth: 400}} />
        <TextInput source="key_template" label="Key Template" fullWidth/>
        <BooleanInput source="is_active" />
        <ArrayInput source="items" label="Items" style={{ width: "100%" }}>
          <SimpleFormIterator inline fullWidth>
            <TextInput source="key" style={{ width: "45%" }} />
            <TextInput source="value" style={{ width: "45%" }} />
          </SimpleFormIterator>
        </ArrayInput>
      </Stack>
    </SimpleForm>
  </Edit>
);

export const LookupCreate = (props: any) => {
  console.log("LookupCreate");
  return (
    <Create {...props}>
      <SimpleForm>
        <Stack spacing={1} style={{ width: "100%" }}>
          <TextInput source="name" sx={{ maxWidth: 400}} />
          <TextInput source="description" sx={{ maxWidth: 400}} />
          <TextInput source="key_template" label="Key Template" fullWidth/>
          <BooleanInput source="is_active" />
          <ArrayInput source="items" label="Items" style={{ width: "100%" }}>
            <SimpleFormIterator inline fullWidth>
              <TextInput source="key" style={{ width: "45%" }} />
              <TextInput source="value" style={{ width: "45%" }} />
            </SimpleFormIterator>
          </ArrayInput>
        </Stack>
      </SimpleForm>
    </Create>
  );
};
