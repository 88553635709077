/* tslint:disable */
/* eslint-disable */
/**
 * middleware
 * middleware API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { HTTPValidationError } from '../models';
// @ts-ignore
import { Request } from '../models';
/**
 * RequestsApi - axios parameter creator
 * @export
 */
export const RequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Request
         * @param {number} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequest: async (requestId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestId' is not null or undefined
            assertParamExists('getRequest', 'requestId', requestId)
            const localVarPath = `/api/v1/requests/{request_id}`
                .replace(`{${"request_id"}}`, encodeURIComponent(String(requestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2AuthorizationCodeBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2AuthorizationCodeBearer", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Requests
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequests: async (sort?: string, range?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2AuthorizationCodeBearer required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "OAuth2AuthorizationCodeBearer", [], configuration)

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RequestsApi - functional programming interface
 * @export
 */
export const RequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Request
         * @param {number} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRequest(requestId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Request>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRequest(requestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Requests
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRequests(sort?: string, range?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Request>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRequests(sort, range, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RequestsApi - factory interface
 * @export
 */
export const RequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RequestsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Request
         * @param {number} requestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequest(requestId: number, options?: any): AxiosPromise<Request> {
            return localVarFp.getRequest(requestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Requests
         * @param {string} [sort] Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
         * @param {string} [range] Format: &#x60;[start, end]&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequests(sort?: string, range?: string, options?: any): AxiosPromise<Array<Request>> {
            return localVarFp.getRequests(sort, range, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getRequest operation in RequestsApi.
 * @export
 * @interface RequestsApiGetRequestRequest
 */
export interface RequestsApiGetRequestRequest {
    /**
     * 
     * @type {number}
     * @memberof RequestsApiGetRequest
     */
    readonly requestId: number
}

/**
 * Request parameters for getRequests operation in RequestsApi.
 * @export
 * @interface RequestsApiGetRequestsRequest
 */
export interface RequestsApiGetRequestsRequest {
    /**
     * Format: &#x60;[\&quot;field_name\&quot;, \&quot;direction\&quot;]&#x60;
     * @type {string}
     * @memberof RequestsApiGetRequests
     */
    readonly sort?: string

    /**
     * Format: &#x60;[start, end]&#x60;
     * @type {string}
     * @memberof RequestsApiGetRequests
     */
    readonly range?: string
}

/**
 * RequestsApi - object-oriented interface
 * @export
 * @class RequestsApi
 * @extends {BaseAPI}
 */
export class RequestsApi extends BaseAPI {
    /**
     * 
     * @summary Get Request
     * @param {RequestsApiGetRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsApi
     */
    public getRequest(requestParameters: RequestsApiGetRequestRequest, options?: AxiosRequestConfig) {
        return RequestsApiFp(this.configuration).getRequest(requestParameters.requestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Requests
     * @param {RequestsApiGetRequestsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RequestsApi
     */
    public getRequests(requestParameters: RequestsApiGetRequestsRequest = {}, options?: AxiosRequestConfig) {
        return RequestsApiFp(this.configuration).getRequests(requestParameters.sort, requestParameters.range, options).then((request) => request(this.axios, this.basePath));
    }
}
