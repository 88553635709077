import React from "react";
import { MenuItemLinkProps } from "react-admin";

export type MenuContextValue = {
  additionalItems: MenuItemLinkProps[];
};

export const MenuContext = React.createContext<MenuContextValue>({
  additionalItems: [],
});

export const MenuContextProvider = MenuContext.Provider;

export const useMenuContext = () => React.useContext(MenuContext);
