import {
  Datagrid,
  ShowButton,
  List,
  TextField,
  BooleanField,
  Show,
  DateField,
  SimpleShowLayout,
  useRecordContext,
  Labeled,
  ReferenceField,
} from "react-admin";
import { Grid } from "@mui/material";
import { Stack } from "@mui/material";
import CodeMirror from "@uiw/react-codemirror";
import { json } from "@codemirror/lang-json";
import { CLOSED_DRAWER_WIDTH } from "react-admin";
import { styled } from "@mui/material/styles";
import InputIcon from '@mui/icons-material/Login';
import OutputIcon from '@mui/icons-material/Logout';


export const RequestList = (props: any) => (
  <List {...props} filters={[]} sort={{ field: 'created_at', order: 'DESC' }} exporter={false}>
    <Datagrid>
      <DateField source="created_at" showTime/>
      <BooleanField source="is_input" label="Direction" TrueIcon={InputIcon} FalseIcon={OutputIcon} valueLabelTrue={"Input"} valueLabelFalse={"Output"} />
      <TextField source="path" />
      <ReferenceField source="account_id" reference="accounts" label="Account" />
      <ReferenceField source="connector_id" reference="connectors" label="Connector" />
      <BooleanField source="response.is_ok" />
      <TextField source="response.duration" />
      <ShowButton />
    </Datagrid>
  </List>
);

const CodeView = (props: any) => {
  let record = useRecordContext();
  if (!record) return null;
  if (!props.source) return null;
  let source = props.source
  if (source.includes(".")) {
    let parts = source.split(".")
    let base = parts[0]
    source = parts[1]
    record = record[base]
  }
  let value = record[source] ?? "";
  try {
    value = JSON.stringify(JSON.parse(value), null, 2)
  } catch (e) {}
  return <Container container spacing={2}><CodeMirror
    value={value}
    editable={false}
    width={props.width ?? ""}
    height={props.height ?? ""}
    extensions={[json()]}
    basicSetup={{
      lineNumbers: false,
    }}
  /></Container>;
};

const RequestLayout = () => {
  const record = useRecordContext();
  return (
    <SimpleShowLayout>
      <Grid container spacing={10}>
        <Grid xs={6} item>
          <Stack spacing={{ xs: 1 }} direction="row" flexWrap="wrap">
            <Labeled><DateField source="created_at" showTime label="Time" /></Labeled>
            <Labeled><BooleanField source="is_input" label="Direction" TrueIcon={InputIcon} FalseIcon={OutputIcon} valueLabelTrue={"Input"} valueLabelFalse={"Output"} /></Labeled>
            <Labeled><TextField source="path" label="Endpoint" /></Labeled>
          </Stack>
          <Stack spacing={{ xs: 1 }} direction="row" flexWrap="wrap">
            <Labeled><ReferenceField source="account_id" reference="accounts" label="Account" /></Labeled>
            <Labeled><ReferenceField source="connector_id" reference="connectors" label="Connector" /></Labeled>
          </Stack>
          <Stack spacing={{ xs: 1 }} direction="column" flexWrap="wrap">
            <Labeled><CodeView source="body" width="40vw" height="40vh" label="Body" /></Labeled>
            <Labeled><CodeView source="headers" width="40vw" height="20vh" label="Headers" /></Labeled>
          </Stack>
        </Grid>
        {record?.response?.id ? (
          <Grid xs={6} item>
            <Stack spacing={{ xs: 1 }} direction="row" flexWrap="wrap">
              <Labeled><DateField source="response.created_at" showTime label="Response Time" /></Labeled>
              <Labeled><TextField source="response.duration" label="Duration Seconds" /></Labeled>
              <Labeled><BooleanField source="response.is_ok" label="Is Successful" /></Labeled>
            </Stack>
            <Stack spacing={{ xs: 1 }} direction="row" flexWrap="wrap">
              <Labeled><TextField source="response.status_code" label="Response Code" /></Labeled>
              <Labeled><TextField source="response.status_text" label="Response Status" /></Labeled>
            </Stack>
            <Stack spacing={{ xs: 1 }} direction="column" flexWrap="wrap">
              <Labeled><CodeView source="response.body" width="40vw" height="40vh" label="Response Body" /></Labeled>
              <Labeled><CodeView source="response.headers" width="40vw" height="20vh" label="Response Headers" /></Labeled>
            </Stack>
          </Grid>
        ) : (
          <Grid xs={6} item>
              No response
          </Grid>
        )}
      </Grid>
    </SimpleShowLayout>
  )
}

export const RequestShow = (props: any) => (
  <Show {...props}>
    <RequestLayout/>
  </Show>
);

const Container = styled(Grid)(() => ({
  width: "100%",
  maxWidth: `calc(100vw - ${CLOSED_DRAWER_WIDTH + 32}px)`,
  fontSize: "10px",
}));
