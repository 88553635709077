import {
  Datagrid,
  Create,
  Edit,
  EditButton,
  List,
  SimpleForm,
  TextField,
  TextInput,
  BooleanInput,
  BooleanField,
} from "react-admin";

export const AccountList = (props: any) => (
  <List {...props} filters={[]} exporter={false}>
    <Datagrid>
      <TextField source="client_id" />
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="prefix" />
      <BooleanField source="is_active" />
      <EditButton />
    </Datagrid>
  </List>
);

export const AccountCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm sx={{ maxWidth: 500 }}>
      <TextInput source="client_id" fullWidth />
      <TextInput source="name" fullWidth />
      <TextInput source="description" fullWidth />
      <TextInput source="prefix" fullWidth />
      <BooleanInput source="is_active" />
    </SimpleForm>
  </Create>
);

export const AccountEdit = (props: any) => (
  <Edit {...props}>
    <SimpleForm sx={{ maxWidth: 500 }}>
      <TextInput source="client_id" fullWidth />
      <TextInput source="name" fullWidth />
      <TextInput source="description" fullWidth />
      <TextInput source="prefix" fullWidth />
      <BooleanInput source="is_active" />
    </SimpleForm>
  </Edit>
);
