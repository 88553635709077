import {
  CLOSED_DRAWER_WIDTH,
  DRAWER_WIDTH,
  DashboardMenuItem,
  MenuClasses,
  MenuProps,
  ResourceMenuItem,
  useResourceDefinitions,
  useSidebarState,
  MenuItemLink,
} from "react-admin";
import clsx from "clsx";
import lodashGet from "lodash/get";
import { styled } from "@mui/material/styles";
import { MenuList } from "@mui/material";
import { useMenuContext } from "./MenuContext";

const PREFIX = "RaMenu";

export const Menu = (props: MenuProps) => {
  const resources = useResourceDefinitions();
  const { additionalItems } = useMenuContext();
  const {
    hasDashboard,
    children = [
      hasDashboard ? (
        <DashboardMenuItem key="default-dashboard-menu-item" />
      ) : null,
      ...Object.keys(resources)
        .filter((name) => resources[name].hasList)
        .map((name) => <ResourceMenuItem key={name} name={name} />),
      ...additionalItems.map((item) => (
        <MenuItemLink key={item.to as string} {...item} />
      )),
    ],
    className,
    ...rest
  } = props;

  const [open] = useSidebarState();

  return (
    <Root
      className={clsx(
        {
          [MenuClasses.open]: open,
          [MenuClasses.closed]: !open,
        },
        className
      )}
      {...rest}
    >
      {children}
    </Root>
  );
};

const Root = styled(MenuList, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  marginTop: "0.5em",
  marginBottom: "1em",
  [theme.breakpoints.only("xs")]: {
    marginTop: 0,
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  [`&.${MenuClasses.open}`]: {
    width: lodashGet(theme, "sidebar.width", DRAWER_WIDTH),
  },

  [`&.${MenuClasses.closed}`]: {
    width: lodashGet(theme, "sidebar.closedWidth", CLOSED_DRAWER_WIDTH),
  },
}));
